@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-custom {
    min-height: 100vh;
    height: auto;
    background-color: #282c34;
    width: 100%;
  }
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}